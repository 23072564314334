import { useState, useRef } from 'react'
import '../styles/contacts.css'
import emailjs from '@emailjs/browser'

const Form = () => {

    const form = useRef();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [message, setMessage] = useState('')

    const sendEmail = (e) => {

        e.preventDefault()

        emailjs.sendForm('service_m7i576r', 'template_kz9h1rd', form.current, 'V0B2LD9tJQ5x2I8j3')
            .then(
                (result) => {
                    if (result.text === 'OK') {
                        setName('')
                        setEmail('')
                        setNumber('')
                        setMessage('')
                        document.getElementById('msg-notif').style.display = 'block'
                        setTimeout(() => {
                            document.getElementById('msg-notif').style.display = 'none'
                        }, 5000)
                    }
                    else {
                        document.getElementById('msg-notif-failed').style.display = 'block'
                        setTimeout(() => {
                            document.getElementById('msg-notif-failed').style.display = 'none'
                        }, 5000)
                    }
                }, (error) => {
                    console.log(error.text)
                }
            )
    }

    return (
        <>
            <form ref={form} onSubmit={sendEmail}>
                <div className='inputs'>
                    <label htmlFor='name'>Full Name : </label>
                    <input id='name' name='name' value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Enter your Name... ' required />
                </div>
                <div className='inputs'>
                    <label htmlFor='email'>Email : </label>
                    <input id='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} type='email' placeholder='Enter your Email... ' required />
                </div>
                <div className='inputs'>
                    <label htmlFor='number'>Number : </label>
                    <input id='number' name='number' value={number} onChange={(e) => setNumber(e.target.value)} type='text' placeholder='Enter your Phone no... ' required />
                </div>
                <div className='inputs'>
                    <label htmlFor='msg'>Message : </label>
                    <textarea id='msg' name='message' value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Enter your message..' required></textarea>
                </div>
                <div className='message-notif' id='msg-notif'>
                    Thank you for reaching out. Your message has been successfully sent. We appreciate your interest and will respond shortly
                </div>
                <div className='message-notif-failed' id='msg-notif-failed'>
                    We apologize, but there was an error on our server's end while processing your message. Please refresh the page and attempt to send it again.
                </div>
                <div className='form-submits'>
                    <button>Submit</button>
                </div>
            </form>
        </>
    )
}

export default Form