
const team = [
    {
        name: 'ENGR. JEROME H. EBUENGA',
        position: 'PRESIDENT – GENERAL MANAGER',
        img: 'jerome.png'
    },
    {
        name: 'ENGR. GENO G. LACHICA',
        position: 'VICE PRESIDENT – GENERAL MANAGER',
        img: 'geno.png'
    },
    {
        name: 'ENGR. ALDWIN JOHN B. LACHICA',
        position: 'OPERATION INCHARGE',
        img: 'unknown.png'
    },
    {
        name: 'ENGR. RAMIR CLAVEJO',
        position: 'OPERATION INCHARGE',
        img: 'unknown.png'
    },
]

export { team }