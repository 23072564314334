import '../styles/video.css'
import { useEffect, useRef } from 'react'
import vid from '../images/videos/v3.mp4'
import { useIsInViewport } from '../hooks/useIsInViewport'

const Video = () => {

    const vidref = useRef(null)
    const v = useIsInViewport(vidref)

    useEffect(() => {
        const vid = document.getElementById('vid')
        if (v) {
            vid.play()
        }
        else {
            vid.pause()
        }
    }, [v])

    return (
        <>
            <div className='vid-container'>
                <video id='vid' loop muted ref={vidref}>
                    <source src={vid} type='video/mp4' />
                </video>
            </div>
        </>
    )
}

export default Video