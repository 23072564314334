import { useEffect } from 'react'
import Footer from '../components/Footer'
import Divider from '../components/Divider'
import Client from '../components/Client'
import Welcome from '../components/Welcome'
import Display from '../components/Display'
import Video from '../components/Video'
import Intro from '../components/Intro'
import Service from '../components/Service'
import Project from '../components/Project'
import ContactForm from '../components/ContactForm'

const Home = () => {

   useEffect(() => { window.scrollTo(0, 0) }, [])

   return (
      <>
         <Welcome />
         <Video />
         <Intro />
         <Display />
         <Divider />
         <h1 className='b-text-title'>Services We Provide</h1>
         <Service />
         <h1 className='b-text-title'>Recent Projects</h1>
         <Project />
         <h1 className='b-text-title'>Our Clients </h1>
         <Client />
         <ContactForm />
         <Footer />
      </>
   )
}

export default Home
