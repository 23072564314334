import '../styles/project.css'
import { Link } from 'react-router-dom'
import { useIsInViewport } from '../hooks/useIsInViewport'
import { projects } from '../data/projects'
import { getService } from '../hooks/useServiceIcon'
import { useRef } from 'react'

const Project = () => {

    const proj_1 = useRef(null)
    const p1 = useIsInViewport(proj_1)

    const proj_2 = useRef(null)
    const p2 = useIsInViewport(proj_2)

    const proj_3 = useRef(null)
    const p3 = useIsInViewport(proj_3)

    const proj_4 = useRef(null)
    const p4 = useIsInViewport(proj_4)

    const setView = (project) => {
        window.sessionStorage.setItem('viewer-data', JSON.stringify(project))
    }

    return (
        <>
            <div className='projects-container'>
                <div className={p1 ? 'project padded' : 'project'} ref={proj_1} >
                    <h3>{projects[0].projectName}</h3>
                    <p>{projects[0].company}</p>
                    <img src={require(`../images/projects/${projects[0].images[0]}`)} alt='' />
                    <div className='project-link'>
                        <Link
                            to='/project'
                            onClick={() => setView(projects[0])}
                        >
                            View Project
                        </Link>
                        <div className='img-count'>
                            {getService(projects[0].serviceType)}
                        </div>
                    </div>
                </div>
                <div className={p2 ? 'project padded' : 'project'} ref={proj_2}>
                    <h3>{projects[1].projectName}</h3>
                    <p>{projects[1].company}</p>
                    <img src={require(`../images/projects/${projects[1].images[0]}`)} alt='' />
                    <div className='project-link'>
                        <Link
                            to='/project'
                            onClick={() => setView(projects[1])}
                        >
                            View Project
                        </Link>
                        <div className='img-count'>
                            {getService(projects[1].serviceType)}
                        </div>
                    </div>
                </div>
                <div className={p3 ? 'project padded' : 'project'} ref={proj_3} >
                    <h3>{projects[2].projectName}</h3>
                    <p>{projects[2].company}</p>
                    <img src={require(`../images/projects/${projects[2].images[0]}`)} alt='' />
                    <div className='project-link'>
                        <Link
                            to='/project'
                            onClick={() => setView(projects[2])}
                        >
                            View Project
                        </Link>
                        <div className='img-count'>
                            {getService(projects[2].serviceType)}
                        </div>
                    </div>
                </div>
                <div className={p4 ? 'project padded' : 'project'} ref={proj_4}>
                    <h3>{projects[3].projectName}</h3>
                    <p>{projects[3].company}</p>
                    <img src={require(`../images/projects/${projects[3].images[0]}`)} alt='' />
                    <div className='project-link'>
                        <Link
                            to='/project'
                            onClick={() => setView(projects[3])}
                        >
                            View Project
                        </Link>
                        <div className='img-count'>
                            {getService(projects[3].serviceType)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Project