import { FaFireExtinguisher, FaArrowUpFromWaterPump } from 'react-icons/fa6'
import { CgIfDesign } from 'react-icons/cg'
import { MdConstruction } from 'react-icons/md'
import { GiCctvCamera, GiLightningTrio } from 'react-icons/gi'
import { IoWaterSharp } from 'react-icons/io5'
import { TbCircuitGround } from "react-icons/tb"

const getService = (service) => {
    switch (service) {
        case 'Fire Protection System':
            return <FaFireExtinguisher title={service} className='icn' />
        case 'Sewage Treatment Plant':
            return <FaArrowUpFromWaterPump title={service} className='icn' />
        case 'Water Supply System':
            return <IoWaterSharp title={service} className='icn' />
        case 'Design Estimate':
            return <CgIfDesign title={service} className='icn' />
        case 'Civil Works':
            return <MdConstruction title={service} className='icn' />
        case 'CCTV':
            return <GiCctvCamera title={service} className='icn' />
        case 'Grounding System':
            return <TbCircuitGround title={service} className='icn' />
        case 'Lightning Arrestor':
            return <GiLightningTrio title={service} className='icn' />
        default:
            return <FaFireExtinguisher title={service} className='icn' />
    }
}

export { getService }