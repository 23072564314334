import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './Layout'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Contacts from './pages/Contacts'
import Projects from './pages/Projects'
import Viewer from './pages/Viewer'
import Quote from './pages/Quote'
import Privacy from './pages/Privacy'

function App() {
   return (
      <BrowserRouter>
         <Routes>
            <Route path='/' element={<Layout />}>
               <Route index element={<Home />} />
               <Route path='about' element={<About />} />
               <Route path='services' element={<Services />} />
               <Route path='projects' element={<Projects />} />
               <Route path='contact' element={<Contacts />} />
               <Route path='project' element={<Viewer />} />
               <Route path='quote' element={<Quote />} />
               <Route path='privacy' element={<Privacy />} />
            </Route>
         </Routes>
      </BrowserRouter>
   )
}

export default App
