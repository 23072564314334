import '../styles/contacts.css'
import Form from './Form'
import { useIsInViewport } from '../hooks/useIsInViewport'
import { useRef } from 'react'

const ContactForm = () => {

    const contact = useRef(null)
    const c1 = useIsInViewport(contact)

    return (
        <>
            <div className='contact-form'>
                <div className='contact-left'>
                    <iframe
                        title='map'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.69916430185!2d121.01774127444587!3d14.55918828592226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c908c61d5aa3%3A0x94a9d883248055bd!2sThe%20Valero%20Tower!5e0!3m2!1sen!2sph!4v1692660201396!5m2!1sen!2sph'
                        style={{ border: '0', width: '100%', height: '100%' }}
                        allowFullScreen
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                    ></iframe>
                </div>
                <div className={c1 ? 'contact-right scaled' : 'contact-right'} ref={contact}>
                    <h3>Message us and Let’s talk about your plans</h3>
                    <Form />
                </div>
            </div>
        </>
    )
}

export default ContactForm