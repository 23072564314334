import '../styles/info.css'
import logo from '../images/jgc2.png'

const Info = () => {
    return (
        <>
            <div className='about-info'>
                <div className='about-image'>
                    <img alt='' src={logo} />
                </div>
                <div className='about-texts'>
                    <h2>About Us</h2>
                    <p>J.G Crystal King Engineering Services is a leading provider of innovative engineering solutions that combine creativity, precision, and expertise. With a legacy rooted in excellence, we offer a comprehensive range of engineering services that cater to diverse industries, ensuring optimal performance, sustainability, and growth.</p>
                    <br />
                    <h2>Our Approach</h2>
                    <p>At J.G Crystal King Engineering Services, we believe in pushing the boundaries of what's possible. Our team of seasoned engineers, designers, and technicians collaborate to deliver solutions that are not only functional but also aesthetically pleasing and forward-thinking.</p>
                    <br />
                    <h2>Our Mission</h2>
                    <p>Our mission is to empower industries and businesses with innovative engineering solutions that transcend conventional boundaries. Through unwavering commitment to excellence, creativity, and sustainability, we strive to reshape the future of engineering by delivering projects that not only meet but exceed our clients' expectations. We aim to be a driving force in engineering, fostering progress, and contributing positively to the global community.</p>
                    <br />
                    <h2>Our Vision</h2>
                    <p>Our vision is to be a renowned global leader in engineering services, recognized for our unparalleled commitment to innovation, precision, and transformative solutions. We envision a world where engineering harmoniously blends with creativity, propelling industries towards unprecedented heights of success. We aspire to be the preferred choice for clients seeking visionary engineering services that not only solve problems but also pave the way for a more sustainable, efficient, and interconnected world.</p>
                </div>
            </div>
        </>
    )
}

export default Info