import '../styles/permits.css'
import ser1 from '../images/certification/sec.png'
import ser2 from '../images/certification/PIFPO.png'
import ser3 from '../images/certification/makati.png'
import ser4 from '../images/certification/BIR.png'

const Permits = () => {
    return (
        <>
            <div className='about-cert'>
                <div className='cert'>
                    <img src={ser1} alt='' />
                </div>
                <div className='cert'>
                    <img src={ser2} alt='' />
                </div>
                <div className='cert'>
                    <img src={ser3} alt='' />
                </div>
                <div className='cert'>
                    <img src={ser4} alt='' />
                </div>
            </div>
        </>
    )
}

export default Permits