import { Outlet } from 'react-router-dom'
import Navbar from './components/Navbar'
import { createContext, useState } from 'react'
const ServiceData = createContext(null)

const Layout = () => {

   const [isRedirected, setIsRedirected] = useState(false)
   const [serviceHash, setServiceHash] = useState('')

   return (
      <>
         <ServiceData.Provider value={{ isRedirected, setIsRedirected, serviceHash, setServiceHash }}>
            <Navbar />
            <Outlet />
         </ServiceData.Provider>
      </>
   )
}

export default Layout
export { ServiceData }