import '../styles/partners.css'
import part1 from '../images/partners/partner1.jpg'
import part2 from '../images/partners/partner2.jpg'
import part3 from '../images/partners/partner3.jpg'

const Partners = () => {
    return (
        <>
            <div className='about-part'>
                <div className='part'>
                    <img src={part2} alt='' />
                </div>
                <div className='part'>
                    <img src={part1} alt='' />
                </div>
                <div className='part'>
                    <img src={part3} alt='' />
                </div>
            </div>
        </>
    )
}

export default Partners