
const clients = {
    clientList: [
        {
            id: 1,
            clientName: 'ACLC',
            logo: 'c1.webp'
        },
        {
            id: 2,
            clientName: 'Amarahs Corner',
            logo: 'c2.jfif'
        },
        {
            id: 3,
            clientName: 'Angel`s Pizza',
            logo: 'c3.png'
        },
        {
            id: 4,
            clientName: 'Ashford Pharmacy',
            logo: 'c4.jpg'
        },
        {
            id: 5,
            clientName: 'Avon',
            logo: 'c5.png'
        },
        {
            id: 6,
            clientName: 'Colegio sto. Domingo',
            logo: 'c6.png'
        },
        {
            id: 7,
            clientName: 'Cribs Foundation',
            logo: 'c7.png'
        },
        {
            id: 8,
            clientName: 'Gapuzan Movers',
            logo: 'c8.jfif'
        },
        {
            id: 9,
            clientName: 'Gaisano Sorsogon',
            logo: 'c9.jfif'
        },
        {
            id: 10,
            clientName: 'Hermit Gas',
            logo: 'c10.png'
        },
        {
            id: 11,
            clientName: 'Honda',
            logo: 'c11.png'
        },
        {
            id: 12,
            clientName: 'Lazada',
            logo: 'c12.png'
        },
        {
            id: 13,
            clientName: 'Longos Super Health',
            logo: 'c13.jfif'
        },
        {
            id: 14,
            clientName: 'Mitsuba',
            logo: 'c14.png'
        },
        {
            id: 15,
            clientName: 'Morong Doctors',
            logo: 'c15.png'
        },
        {
            id: 16,
            clientName: 'My Brush',
            logo: 'c16.png'
        },
        {
            id: 17,
            clientName: 'Omni Solid',
            logo: 'c17.png'
        },
        {
            id: 18,
            clientName: 'PIC',
            logo: 'c18.png'
        },
        {
            id: 19,
            clientName: 'Pulsar',
            logo: 'c19.png'
        },
        {
            id: 20,
            clientName: 'Siantago`s Furniture',
            logo: 'c20.jfif'
        }
    ]
}

export { clients }