import '../styles/services.css'
import { Link } from 'react-router-dom'
import { useContext, useRef } from 'react'
import { ServiceData } from '../Layout'
import { services } from '../data/services'
import { getService } from '../hooks/useServiceIcon'
import { useIsInViewport } from '../hooks/useIsInViewport'

const Service = () => {

    const { setIsRedirected, setServiceHash } = useContext(ServiceData)

    const hex1 = useRef(null)
    const h1 = useIsInViewport(hex1)

    const hex2 = useRef(null)
    const h2 = useIsInViewport(hex2)

    const hex3 = useRef(null)
    const h3 = useIsInViewport(hex3)

    const hex4 = useRef(null)
    const h4 = useIsInViewport(hex4)

    const hex5 = useRef(null)
    const h5 = useIsInViewport(hex5)

    const hex6 = useRef(null)
    const h6 = useIsInViewport(hex6)

    const hex7 = useRef(null)
    const h7 = useIsInViewport(hex7)

    const hex8 = useRef(null)
    const h8 = useIsInViewport(hex8)

    const animes = [
        {
            ref: hex1,
            detector: h1
        },
        {
            ref: hex2,
            detector: h2
        },
        {
            ref: hex3,
            detector: h3
        },
        {
            ref: hex4,
            detector: h4
        },
        {
            ref: hex5,
            detector: h5
        },
        {
            ref: hex6,
            detector: h6
        },
        {
            ref: hex7,
            detector: h7
        },
        {
            ref: hex8,
            detector: h8
        }
    ]

    return (
        <>
            <div className='services'>
                <div className='contents'>
                    {
                        services.map((service, id) => (
                            <div className='service' key={id}>
                                <div className={animes[id].detector ? 'hexagon animated' : 'hexagon'} ref={animes[id].ref}>
                                    <div className='hexs' >
                                        {getService(service.title)}
                                    </div>
                                </div>
                                <div className='title'>
                                    <h3>{service.title}</h3>
                                    <p>{service.description}</p>
                                    <div className='btn-container'>
                                        <Link
                                            to={'/services'}
                                            onClick={() => {
                                                setIsRedirected(true)
                                                setServiceHash(service.title)
                                            }}
                                        >
                                            Learn more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default Service