const services = [
    {
        title: 'Fire Protection System',
        description: 'At J.G. Crystal King, we understand that fire safety is paramount for any environment. Our Fire Protection Systems division is dedicated to engineering comprehensive solutions that mitigate fire risks and provide robust protection against potential disasters. With a blend of cutting-edge technology, engineering excellence, and a commitment to safety, we offer a range of fire protection services tailored to meet the unique needs of commercial, industrial, and residential spaces.',
        servicesList: [
            'Fire Detection and Alarm System (FDAS)',
            'Sprinkler System',
            'Standpipe System',
            'Foam System',
            'Fire Pump & Jockey Pump Assembly',
            'Kitchen Fire Suppression'
        ]
    },
    {
        title: 'Sewage Treatment Plant',
        description: 'At J.G. Crystal King, we believe in sustainable solutions that preserve and protect our environment. Our Sewage Treatment Plant division is dedicated to engineering state-of-the-art facilities that effectively treat wastewater, ensuring cleaner ecosystems and healthier communities. With a blend of innovation, expertise, and environmental consciousness, we offer comprehensive sewage treatment solutions tailored to meet the evolving needs of modern society.',
        servicesList: [
            'Plant Design and Planning',
            'Primary and Secondary Treatment',
            'Tertiary Treatment and Disinfection',
            'Sludge Management',
            'Advanced Technologies',
            'Sustainability Integration'
        ]
    },
    {
        title: 'Water Supply System',
        description: 'At J.G. Crystal King, we believe that access to clean and reliable water is fundamental to a thriving society. Our Water Supply Systems division is dedicated to designing, developing, and implementing innovative water solutions that cater to the diverse needs of communities, industries, and beyond. With a blend of engineering excellence, environmental consciousness, and cutting-edge technology, we are your trusted partner for water supply system solutions.',
        servicesList: [
            'Booster & Transfer System',
            'Water Tanks & Pressure Tanks Installation',
            'Source Assessment and Development',
            'Infrastructure Design and Planning',
            'Pumping and Treatment Solutions',
            'Storage Reservoirs and Tanks',
            'Smart Monitoring and Control'
        ]
    },
    {
        title: 'Design Estimate',
        description: 'At J.G. Crystal King, we understand that every successful project begins with a solid plan and a clear understanding of costs. Our Design Estimates Services division is dedicated to providing accurate and comprehensive estimates that empower you to make informed decisions and bring your ideas to life. With a blend of creativity, precision, and transparency, we offer a range of design estimation services tailored to your specific needs.',
        servicesList: [
            'Conceptual Estimations',
            'Detailed Cost Breakdown',
            'Material Cost Analysis',
            'Labor and Resource Allocation',
            'Timeline Projections',
            'Risk Assessment',
            'Value Engineering',
        ]
    },
    {
        title: 'Civil Works',
        description: 'At J.G. Crystal King, we believe that solid foundations are the bedrock of progress. Our Civil Works Services division is dedicated to crafting infrastructure solutions that shape the world around us. With a blend of expertise, innovation, and commitment, we provide comprehensive civil engineering services that meet the diverse needs of communities, industries, and beyond.',
        servicesList: [
            'Site Development',
            'Infrastructure Design',
            'Structural Engineering',
            'Foundation Engineering',
            'Earthworks and Excavation',
            'Water and Wastewater Management',
            'Land Surveying',
            'Project Management'
        ]
    },
    {
        title: 'CCTV',
        description: 'At J.G. Crystal King, we understand that security and surveillance play a pivotal role in safeguarding what matters most to you. Our CCTV Services division is dedicated to providing state-of-the-art surveillance solutions that combine cutting-edge technology with our commitment to excellence. With a focus on precision, reliability, and innovation, we offer a comprehensive range of CCTV services tailored to meet your unique security needs.',
        servicesList: [
            'Customized System Design',
            'Camera Selection and Installation',
            'High-Resolution Imaging',
            'Remote Monitoring',
            'Motion Detection and Alerts',
            'Integration with Access Contro',
            '24/7 Support and Maintenance'
        ]
    },
    {
        title: 'Grounding System',
        description: 'At J.G. Crystal King, we understand that safety and asset protection are paramount in any electrical or industrial system. Our Grounding Systems division is dedicated to engineering robust and reliable grounding solutions that safeguard lives, equipment, and structures from electrical hazards. With a blend of expertise, precision, and a commitment to safety, we offer a comprehensive range of grounding services tailored to meet the unique needs of various industries',
        servicesList: [
            'Grounding Design and Analysis',
            'Grounding Electrode Installation',
            'Safety Grounding',
            'Ground Fault Protection'
        ]
    },
    {
        title: 'Lightning Arrestor',
        description: 'At J.G. Crystal King, we recognize the destructive force of lightning strikes and their potential to wreak havoc on structures and electrical systems. Our Lightning Arrestor division is dedicated to engineering solutions that protect your assets, personnel, and equipment from the devastating effects of lightning. With a blend of cutting-edge technology, engineering prowess, and a commitment to safety, we offer a comprehensive range of lightning protection services tailored to various industries.',
        servicesList: [
            'Lightning Risk Assessment',
            'Lightning Protection System Design',
            'Installation and Maintenance',
            'Surge Protection Devices'
        ]
    },
]

export { services }