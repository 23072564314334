const projects = [
    {
        projectName: 'Design, Supply and Installation of 20 M3/Day - STP',
        company: 'Longos Super Health Center',
        location: 'Malabon, Metro Manila',
        serviceType: 'Sewage Treatment Plant',
        images: ['p1a.png', 'p1b.png', 'p1c.png']
    },
    {
        projectName: 'Design, Supply and Installation of 10 CMPD - STP',
        company: 'Wet and Dry Market',
        location: 'Sumulong Highway Masinag, Antipolo',
        serviceType: 'Sewage Treatment Plant',
        images: ['p2a.png', 'p2b.png', 'p2c.png']
    },
    {
        projectName: 'Crayola Painting Works',
        company: 'Gaisano Capital Sorsogon',
        location: 'Sorsogon City',
        serviceType: 'Civil Works',
        images: ['p3a.png', 'p3b.png']
    },
    {
        projectName: 'Rehabilitation of 93 Pieces Floor Tiles',
        company: 'Gaisano Capital Sorsogon',
        location: 'Sorsogon City',
        serviceType: 'Civil Works',
        images: ['p4a.png', 'p4b.png']
    },
    {
        projectName: 'Exit Door Adjustment at Roof Deck',
        company: 'Gaisano Capital Sorsogon',
        location: 'Sorsogon City',
        serviceType: 'Civil Works',
        images: ['p5a.png', 'p5b.png']
    },
    {
        projectName: 'Rehabilitation of 117 Pieces Floor Tiles',
        company: 'Gaisano Capital Sorsogon',
        location: 'Sorsogon City',
        serviceType: 'Civil Works',
        images: ['p6a.png', 'p6b.png']
    },
    {
        projectName: 'Replacement of Exhaust Motor Control Contactor',
        company: 'Gaisano Capital Sorsogon',
        location: 'Sorsogon City',
        serviceType: 'Civil Works',
        images: ['p7a.png', 'p7b.png']
    },
    {
        projectName: 'Waterproofing and Painting Works of Exterior Wall',
        company: 'Gaisano Capital Sorsogon',
        location: 'Sorsogon City',
        serviceType: 'Civil Works',
        images: ['p8a.png', 'p8b.png', 'p8c.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Pump Sprinkler System',
        company: 'Zenaida Et Al Building 4 Storey',
        location: 'Antipolo, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p9a.png', 'p9b.png', 'p9c.png']
    },
    {
        projectName: 'Supply and Installation of Jockey Pump Assembly',
        company: 'Hermet Gas Traiding',
        location: 'Taytay, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p10a.png']
    },
    {
        projectName: 'Preventive Maintenance of Fire Detection and Alarm System (FDAS)',
        company: 'Avon Cosmetics',
        location: 'Imus, Cavite',
        serviceType: 'Fire Protection System',
        images: ['p11a.png', 'p11b.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'Santiago’s Furniture',
        location: 'Antipolo, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p12a.png', 'p12b.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'Intersphere 4 Storey Building',
        location: 'Antipolo, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p13a.png', 'p13b.png']
    },
    {
        projectName: 'Preventive Maintenance of Fire Detection and Alarm System (FDAS)',
        company: 'Santiago’s Furniture',
        location: 'Antipolo, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p14a.png', 'p14b.png']
    },
    {
        projectName: 'Preventive Maintenance and Adjustments of Sprinkler System',
        company: 'Angel’s Pizza',
        location: 'Gaisano, Sorsogon City',
        serviceType: 'Fire Protection System',
        images: ['p15a.png', 'p15b.png', 'p15c.png']
    },
    {
        projectName: 'Design, Supply and Installation of Wet Standpipe System',
        company: 'CKL Industries',
        location: 'Imus, Cavite',
        serviceType: 'Fire Protection System',
        images: ['p16a.png', 'p16b.png']
    },
    {
        projectName: 'Supply and Installation of Fire Pump and Jockey Pump Assembly',
        company: 'Gapuzan Movers Inc.',
        location: 'Las Piñas, Metro Manila',
        serviceType: 'Fire Protection System',
        images: ['p17a.png', 'p17b.png']
    },
    {
        projectName: 'Preventive Maintenance of Wet Standpipe System',
        company: 'My Brush Technology Philippine Inc.',
        location: 'LISP and FPIP, Sto. Tomas Batangas',
        serviceType: 'Fire Protection System',
        images: ['p18a.png', 'p18b.png']
    },
    {
        projectName: 'Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'JND Building',
        location: 'Magsaysay Street, Salog Sorsogon City',
        serviceType: 'Fire Protection System',
        images: ['p19a.png', 'p19b.png']
    },
    {
        projectName: 'Preventive Maintenance of Wet Standpipe System',
        company: 'Omni Solid Services Inc.',
        location: 'Parañaque, Metro Manila',
        serviceType: 'Fire Protection System',
        images: ['p20a.png', 'p20b.png']
    },
    {
        projectName: 'Preventive Maintenance of Fire Protection System',
        company: 'Mitsuba Philippine Corporation',
        location: 'Plant 3 Lipa City, Batangas',
        serviceType: 'Fire Protection System',
        images: ['p21a.png', 'p21b.png', 'p21c.png']
    },
    {
        projectName: 'Supply and Installation of Jockey Pump and Preventive Maintenance of Wet Standpipe System',
        company: 'Philippine International College',
        location: 'Sitio Bayugo, Antipolo Rizal',
        serviceType: 'Fire Protection System',
        images: ['p22a.png', 'p22b.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'Ashford Pharmaceutical Laboratories Inc.',
        location: 'Antipolo, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p23a.png', 'p23b.png', 'p23c.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'Colegio Sto. Domingo Educational Foundation Inc.',
        location: 'Barangay San Isidro, Cainta Rizal',
        serviceType: 'Fire Protection System',
        images: ['p24a.png', 'p24b.png', 'p24c.png']
    },
    {
        projectName: 'Preventive Maintenance of Wet Standpipe System',
        company: 'My Brush Technology Philippine Inc.',
        location: 'FPIP, Sto. Tomas Batangas',
        serviceType: 'Fire Protection System',
        images: ['p25a.png', 'p25b.png']
    },
    {
        projectName: 'Preventive Maintenance of Fire Detection and Alarm System (FDAS)',
        company: 'HONDA Philippines Inc.',
        location: 'East Service Road, Parañaque, Metro Manila',
        serviceType: 'Fire Protection System',
        images: ['p26a.png', 'p26b.png', 'p26c.png']
    },
    {
        projectName: 'Supply, Design and Installation of Sprinkler System and Fire Detection and Alarm System (FDAS)',
        company: 'Cribs Foundation Inc.',
        location: 'C. Lawis Extension Brgy. San Luis Antipolo City',
        serviceType: 'Fire Protection System',
        images: ['p27a.png', 'p27b.png', 'p27c.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'Bricore Manufacturing Inc.',
        location: 'Pinagmisahan Street Brgy. Delapaz, Antipolo Rizal',
        serviceType: 'Fire Protection System',
        images: ['p28a.png', 'p28b.png', 'p28c.png']
    },
    {
        projectName: 'Supply and Installation of Jockey Pump and Preventive Maintenance of Fire Detection and Alarm System (FDAS)',
        company: 'Verde Oro East Building',
        location: 'Taytay Diversion Road, Taytay Rizal',
        serviceType: 'Fire Protection System',
        images: ['p29a.png', 'p29b.png']
    },
    {
        projectName: 'Design, Supply and Installation of Foam System',
        company: 'Crystal Resins Inc.',
        location: 'Cabrera Road, Sitio Burol Brgy San Juan, Taytay, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p30a.png', 'p30b.png', 'p30c.png']
    },
    {
        projectName: 'Preventive Maintenance of Fire Protection System',
        company: 'Morong Doctors Hospital',
        location: 'Martinez Morong Rizal',
        serviceType: 'Fire Protection System',
        images: ['p31a.png', 'p31b.png', 'p31c.png']
    },
    {
        projectName: 'Supply, Design and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'Lag Bay Inn East Service Road',
        location: 'Parañaque, Metro Manila',
        serviceType: 'Fire Protection System',
        images: ['p32a.png', 'p32b.png', 'p32c.png']
    },
    {
        projectName: 'Preventive Maintenance of Fire Detection and Alarm System (FDAS)',
        company: 'Q-Plaza Corporation',
        location: 'Felix Ave. Sto. Domingo, Cainta Rizal',
        serviceType: 'Fire Protection System',
        images: ['p33a.png', 'p33b.png']
    },
    {
        projectName: 'Supply and Installation of Fire Pump and Jockey Pump Assembly',
        company: 'Pulsar Hotel',
        location: 'Tuguegarao, City',
        serviceType: 'Fire Protection System',
        images: ['p34a.png', 'p34b.png', 'p34c.png']
    },
    {
        projectName: 'Supply, Design and Installation of Kitchen Fire Suppression',
        company: 'Amarah’s Corner',
        location: 'Morong, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p35a.png', 'p35b.png']
    },
    {
        projectName: 'Preventive Maintenance of Automatic Fire Sprinkler System',
        company: 'Lazada Masinag Hub',
        location: 'Masinag Antipolo Rizal',
        serviceType: 'Fire Protection System',
        images: ['p36a.png', 'p36b.png', 'p36c.png']
    },
    {
        projectName: 'Preventive Maintenance of Fire Detection and Alarm System (FDAS)',
        company: 'Caramia Cakes and Gelato Amca Finefoods Inc.',
        location: 'Cainta, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p37a.png', 'p37b.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'ACLC – College of Taytay',
        location: '#7 El Monteverde Subd., Manila East Road, Taytay, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p38a.png', 'p38b.png', 'p38c.png']
    },
    {
        projectName: 'Design, Supply and Installation of Wet Standpipe System',
        company: 'ACLC – College of Taytay',
        location: '#7 El Monteverde Subd., Manila East Road, Taytay, Rizal',
        serviceType: 'Fire Protection System',
        images: ['p39a.png', 'p39b.png', 'p39c.png']
    },
    {
        projectName: 'Design, Supply and Installation of Wet Standpipe System',
        company: 'Ayala Steel Warehouses',
        location: 'Felix Avenue, Sto Domingo, Cainta Rizal',
        serviceType: 'Fire Protection System',
        images: ['p40a.png', 'p40b.png', 'p40c.png']
    },
    {
        projectName: 'Design, Supply and Installation of Fire Detection and Alarm System (FDAS)',
        company: 'M-CE Centermart',
        location: 'Pilar, Sorsogon',
        serviceType: 'Fire Protection System',
        images: ['p41a.png', 'p41b.png', 'p41c.png']
    },
]

export { projects }