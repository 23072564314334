import '../styles/services.css'
import { useContext, useEffect, useRef } from 'react'
import { useIsInViewport } from '../hooks/useIsInViewport'
import Footer from '../components/Footer';
import Divider from '../components/Divider'
import { services } from '../data/services'
import { getService } from '../hooks/useServiceIcon'
import Hero from '../components/Hero'
import { ServiceData } from '../Layout';

const Services = () => {

   const { isRedirected, serviceHash } = useContext(ServiceData)

   useEffect(() => {
      if (isRedirected) document.getElementById(serviceHash).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      else window.scrollTo(0, 0)
   }, [isRedirected, serviceHash])

   const hex1 = useRef(null)
   const h1 = useIsInViewport(hex1)

   const hex2 = useRef(null)
   const h2 = useIsInViewport(hex2)

   const hex3 = useRef(null)
   const h3 = useIsInViewport(hex3)

   const hex4 = useRef(null)
   const h4 = useIsInViewport(hex4)

   const hex5 = useRef(null)
   const h5 = useIsInViewport(hex5)

   const hex6 = useRef(null)
   const h6 = useIsInViewport(hex6)

   const hex7 = useRef(null)
   const h7 = useIsInViewport(hex7)

   const hex8 = useRef(null)
   const h8 = useIsInViewport(hex8)

   const animes = [
      {
         ref: hex1,
         detector: h1
      },
      {
         ref: hex2,
         detector: h2
      },
      {
         ref: hex3,
         detector: h3
      },
      {
         ref: hex4,
         detector: h4
      },
      {
         ref: hex5,
         detector: h5
      },
      {
         ref: hex6,
         detector: h6
      },
      {
         ref: hex7,
         detector: h7
      },
      {
         ref: hex8,
         detector: h8
      }
   ]

   return (
      <>
         <Hero />
         {
            services.map((service, id) => (
               <div id={service.title} className='service-container' key={id}>
                  <div className='service-left'>
                     <div className={animes[id].detector ? 'hexagon animated' : 'hexagon'} ref={animes[id].ref}>
                        <div className='hexs'>
                           {getService(service.title)}
                        </div>
                     </div>
                  </div>
                  <div className='service-right'>
                     <h3>{service.title}</h3>
                     <p>{service.description}</p>
                     <div className={service.servicesList.length > 0 ? 'lists' : ''}>
                        <h2>Services we offer : </h2>
                        <ul>
                           {
                              service.servicesList.map((list, lid) => (
                                 <li key={lid}>{list}</li>
                              ))
                           }
                        </ul>
                     </div>
                  </div>
               </div>
            ))
         }
         <Divider />
         <br />
         <Footer />
      </>
   )
}

export default Services
