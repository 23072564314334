import { Link } from 'react-router-dom'

const Divider = () => {
    return (
        <>
            <div className='qoutes'>
                <h3>J.G Crystal King Engineering Services Corp sole objective is to provide exceptional quality of work while ensuring the success of every project.</h3>
                <div className='qoutes-form'>
                    <Link
                        to='/quote'
                    >
                        GET FREE QUOTE
                    </Link>
                    <Link
                        to='/contact'
                    >
                        CONTACT US
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Divider