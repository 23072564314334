import '../styles/intro.css'
import { Link } from 'react-router-dom'

const Intro = () => {
    return (
        <>
            <div className='intro'>
                <h1 className='b-text'>Best Engineering Company in the Philippines</h1>
                <p>JG Crystal King Engineering Services Corporation is a group of licensed engineers who provides expert engineering services to both the public and commercial sectors.</p>
                <div>
                    <Link className='learn' to='/about'>Learn more about us</Link>
                </div>
            </div>
        </>
    )
}

export default Intro