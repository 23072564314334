import { useEffect } from 'react'
import '../styles/privacy.css'
import Footer from '../components/Footer'

const Privacy = () => {
    useEffect(() => { window.scrollTo(0, 0) }, [])
    return (
        <>
            <div className='privacy'>
                <h2>Privacy Policy</h2>
                <p>Thank you for choosing J.G. Crystal King Engineering Services. We are committed to protecting your privacy and the confidentiality of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website or use our services.</p>
                <br />
                <h3>1. Information We Collect</h3>
                <p>We may collect the following types of information:</p>
                <ul>
                    <li><strong>Personal Information:</strong> This may include your name, email address, phone number, postal address, and other identifying information when you provide it to us.</li>
                    <li><strong>Non-Personal Information:</strong> This may include information such as your IP address, browser type, device information, and usage data when you interact with our website.</li>
                </ul>
                <br />
                <h3>2. How We Use Your Information</h3>
                <p>We may use your information for the following purposes:</p>
                <ul>
                    <li><strong>Provide Services:</strong> To provide you with the services you request from us.</li>
                    <li><strong>Communications:</strong> To communicate with you, respond to your inquiries, and provide customer support.</li>
                    <li><strong>Marketing:</strong> To send you promotional materials, newsletters, and updates about our services (you can opt out of these communications at any time). </li>
                    <li><strong>Analytics:</strong> To analyze website usage and improve our services.</li>
                </ul>
                <br />
                <h3>3. Sharing Your Information</h3>
                <p>We may share your information with:</p>
                <ul>
                    <li><strong>Third-Party Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in delivering our services.</li>
                    <li><strong>Legal Requirements:</strong> We may disclose your information when required by law or to protect our rights, property, or safety.</li>
                </ul>
                <br />
                <h3>4. Security</h3>
                <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is entirely secure.</p>
                <br />
                <h3>5. Your Choices</h3>
                <p>You can choose not to provide us with certain information, but this may limit your ability to use some of our services. You may also have the option to opt out of certain communications.</p>
                <br />
                <h3>6. Changes to this Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes through our website or other communication channels.</p>
                <br />
                <h3>7. Contact Us</h3>
                <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href='mailto:.gcrystalking@gmail.com'>j.gcrystalking@gmail.com</a> or call us at <a href='tel:+63999-333-6470'>0999-333-6470 - Smart</a> | <a href='tel:+63977-1826-696'>0977-1826-696 - Globe</a></p>
            </div>
            <Footer />
        </>
    )
}

export default Privacy