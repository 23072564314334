import '../styles/vids.css'
import vid1 from '../images/videos/v4.mp4'
import vid2 from '../images/videos/v5.mp4'
import vid3 from '../images/videos/v6.mp4'

const Vids = () => {
    return (
        <>
            <div className='vids'>
                <div className='vid'>
                    <video controls autoPlay loop muted>
                        <source src={vid1} type='video/mp4' />
                    </video>
                </div>
                <div className='vid'>
                    <video controls autoPlay loop muted>
                        <source src={vid2} type='video/mp4' />
                    </video>
                </div>
                <div className='vid'>
                    <video controls autoPlay loop muted>
                        <source src={vid3} type='video/mp4' />
                    </video>
                </div>
            </div>
        </>
    )
}

export default Vids