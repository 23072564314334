import '../styles/team.css'
import { team } from '../data/team'

const Team = () => {
    return (
        <>
            <div className='about-team'>
                {
                    team.map((t, id) => (
                        <div className='team' key={id}>
                            <img src={require(`../images/team/${t.img}`)} alt={t.name} />
                            <h3>{t.name}</h3>
                            <p>{t.position}</p>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Team