import { projects } from '../data/projects'

const check = (obj1, obj2) => {
    let checked = 0

    if (obj1.projectName === obj2.projectName) {
        checked++
    }

    if (obj1.company === obj2.company) {
        checked++
    }

    if (obj1.location === obj2.location) {
        checked++
    }

    if (obj1.serviceType === obj2.serviceType) {
        checked++
    }

    if (checked === 4) return true
    else return false

}

const getRelated = (project) => {

    let related = []
    const maxCount = 4
    let count = 0

    for (let i of projects) {
        if (i.serviceType === project.serviceType) {
            if (!check(i, project)) {
                related.push(i)
                count++
            }
        }
        if (count === maxCount) {
            break
        }
    }

    return related

}

export { getRelated }