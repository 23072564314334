import '../styles/clients.css'
import { useState } from 'react'
import { clients } from '../data/clients'
import { FcNext, FcPrevious } from 'react-icons/fc'

const Client = () => {

    const [current1, setCurrent1] = useState(1)
    const [current2, setCurrent2] = useState(2)
    const [current3, setCurrent3] = useState(3)
    const [move, setMove] = useState(0)

    return (
        <>
            <div className='clients'>
                {
                    clients.clientList.map((img, id) => (
                        <div className={current1 === img.id || current2 === img.id || current3 === img.id ? 'client' : 'dnone'} key={id}>
                            <img src={require(`../images/clients/${img.logo}`)} alt={img.clientName} />
                            <p>{img.clientName}</p>
                        </div>
                    ))
                }
                <button
                    className='prev'
                    onClick={() => {
                        if (move > 0) {
                            setCurrent1(current1 - 1)
                            setCurrent2(current2 - 1)
                            setCurrent3(current3 - 1)
                            setMove(move - 1)
                        }
                    }}
                >
                    <FcPrevious />
                </button>
                <button
                    className='next'
                    onClick={() => {
                        if (move < (clients.clientList.length - 3)) {
                            setCurrent1(current1 + 1)
                            setCurrent2(current2 + 1)
                            setCurrent3(current3 + 1)
                            setMove(move + 1)
                        }
                    }}
                >
                    <FcNext />
                </button>
            </div>
        </>
    )
}

export default Client