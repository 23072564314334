import { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../styles/navbar.css'
import logo from '../images/lg3.png'
import { ImHome, ImInfo } from 'react-icons/im'
import { BsTools, BsFillBoxFill, BsFillTelephoneInboundFill } from 'react-icons/bs'
import { ServiceData } from '../Layout'

const Navbar = () => {

    const location = useLocation()
    const { setIsRedirected } = useContext(ServiceData)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = window.scrollY + window.innerHeight;
            let modifier = 50;
            if (currentScroll + modifier >= documentHeight) {
                document.getElementById('navbar').style.height = '0'
            }
            else {
                document.getElementById('navbar').style.height = '100px'
            }
        })
    }, [])

    return (
        <>
            <nav className='navbar' id='navbar'>
                <div className='nav-brand'>
                    <img src={logo} alt='logo' />
                </div>
                <div className='nav-links'>
                    <Link
                        to='/'
                        className={location.pathname === '/' ? 'active' : ''}
                    >
                        <div className='hexagon'>
                            <ImHome />
                        </div>
                        <div className='link'>Home</div>
                    </Link>
                    <Link
                        to='/about'
                        className={location.pathname === '/about' ? 'active' : ''}
                    >
                        <div className='hexagon'>
                            <ImInfo />
                        </div>
                        <div className='link'>About</div>
                    </Link>
                    <Link
                        to='/services'
                        className={location.pathname === '/services' ? 'active' : ''}
                        onClick={() => {
                            setIsRedirected(false)
                        }}
                    >
                        <div className='hexagon'>
                            <BsTools />
                        </div>
                        <div className='link'>Services</div>
                    </Link>
                    <Link
                        to='/projects'
                        className={location.pathname === '/projects' ? 'active' : ''}
                    >
                        <div className='hexagon'>
                            <BsFillBoxFill />
                        </div>
                        <div className='link'>Projects</div>
                    </Link>
                    <Link
                        to='/contact'
                        className={location.pathname === '/contact' ? 'active' : ''}
                    >
                        <div className='hexagon'>
                            <BsFillTelephoneInboundFill />
                        </div>
                        <div className='link'>Contacts</div>
                    </Link>
                </div>
            </nav>
        </>
    )
}

export default Navbar