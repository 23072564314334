import '../styles/quote.css'
import Footer from '../components/Footer'
import { services } from '../data/services'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

const Quote = () => {

    useEffect(() => { window.scrollTo(0, 0) }, [])

    const form = useRef();
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [address, setAddress] = useState('')
    const [service, setService] = useState('')
    const [message, setMessage] = useState('')

    const sendEmail = (e) => {
        e.preventDefault()
        console.log('im called')
        emailjs.sendForm('service_m7i576r', 'template_xtej7o6', form.current, 'V0B2LD9tJQ5x2I8j3')
            .then(
                (result) => {
                    if (result.text === 'OK') {
                        setName('')
                        setCompany('')
                        setEmail('')
                        setNumber('')
                        setAddress('')
                        setService('')
                        setMessage('')
                        document.getElementById('quote-notif').style.display = 'block'
                        setTimeout(() => {
                            document.getElementById('quote-notif').style.display = 'none'
                        }, 15000)
                    }
                    else {
                        document.getElementById('quote-notif-failed').style.display = 'block'
                        setTimeout(() => {
                            document.getElementById('quote-notif-failed').style.display = 'none'
                        }, 15000)
                    }
                }, (error) => {
                    console.log(error.text)
                }
            )
    }

    return (
        <>
            <div className='quotes-container'>
                <div className='quotes-left'>
                    <div className='quotes-texts'>
                        <h3>J.G Crystal King Engineering Services Corporation. Quote Request Form</h3>
                        <p>Thank you for considering J.G Crystal King Engineering Services for your project needs. Please take a moment to fill out the following information, and our dedicated team will get back to you with a tailored quote that matches your requirements.</p>
                        <p>Please review your information to ensure its accuracy before submitting. Once we receive your request, our team will review your project details and contact you promptly with a customized quote. Thank you for considering J.G Crystal King Engineering Services!</p>
                        <p>If you have any questions or need assistance with this form, please contact us at <a href='mailto:.gcrystalking@gmail.com'>j.gcrystalking@gmail.com</a> or call us at <a href='tel:+63999-333-6470'>0999-333-6470 - Smart</a> | <a href='tel:+63977-1826-696'>0977-1826-696 - Globe</a></p>
                    </div>
                </div>
                <div className='quotes-right'>
                    <div className='quotes-form'>
                        <form ref={form} encType='multipart/form-data' onSubmit={sendEmail}>
                            <div className='quote-group'>
                                <label htmlFor='name'>Full Name : </label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type='text' name='full_name' id='name' placeholder='Enter your Name...' required />
                            </div>
                            <div className='quote-group'>
                                <label htmlFor='company'>Company / Organization : </label>
                                <input value={company} onChange={(e) => setCompany(e.target.value)} type='text' name='company' id='company' placeholder='Enter your Company / Organization...' required />
                            </div>
                            <div className='quote-group'>
                                <label htmlFor='email'>Email : </label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' name='email' id='email' placeholder='Enter your Email...' required />
                            </div>
                            <div className='quote-group'>
                                <label htmlFor='number'>Phone Number : </label>
                                <input value={number} onChange={(e) => setNumber(e.target.value)} type='text' name='number' id='number' placeholder='Enter your Phone Number...' required />
                            </div>
                            <div className='quote-group'>
                                <label htmlFor='address'>Address : </label>
                                <input value={address} onChange={(e) => setAddress(e.target.value)} type='address' name='address' id='address' placeholder='Enter your Address...' required />
                            </div>
                            <div className='quote-group'>
                                <label htmlFor='service'>Type of Service Requested : </label>
                                <select value={service} onChange={(e) => setService(e.target.value)} name='service' id='service' required>
                                    {services.map((service, id) => (
                                        <option value={service.title} key={id}>{service.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='quote-group'>
                                <label htmlFor='description'>Message : </label>
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} name='message' id='description' placeholder='Please provide a brief overview of your project, including its scope, goals, and any specific requirements...' required></textarea>
                            </div>
                            <div className='quote-notif' id='quote-notif'>
                                <p>Your form has been submitted successfully. We appreciate your interest and will review the information you provided.</p>
                                <p>Should there be any further updates or if we require additional details, we will reach out to you using the contact information you provided.</p>
                                <p>Thank you for considering us!</p>
                                <p>Best regards,</p>
                                <p>J.G Crystal King</p>
                            </div>
                            <div className='quote-notif-failed' id='quote-notif-failed'>
                                <p>We apologize, but there was a technical error while submitting your quote request form. Please refresh the page and try again.</p>
                            </div>
                            <div className='quote-btn'>
                                <button>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Quote