import '../styles/intro.css'
import Footer from '../components/Footer'
import { useEffect } from 'react'
import Divider from '../components/Divider'
import Client from '../components/Client'
import Hero from '../components/Hero'
import Vids from '../components/Vids'
import Info from '../components/Info'
import Team from '../components/Team'
import Permits from '../components/Permits'
import Partners from '../components/Parnters'

const Intro = () => {
   return (
      <>
         <div className='intro'>
            <h1 className='b-text'>Best Engineering Company in the Philippines</h1>
            <p>JG Crystal King Engineering Services Corporation boasts a diverse team of highly skilled and experienced licensed engineers. With a strong commitment to excellence, we deliver expert engineering services to cater to the unique needs of both the public and commercial sectors. Our innovative solutions and attention to detail set us apart in the industry. Our company's track record of successful projects speaks volumes about our dedication to quality and client satisfaction.</p>
         </div>
      </>
   )
}

const About = () => {

   useEffect(() => { window.scrollTo(0, 0) }, [])

   return (
      <>
         <Hero />
         <Info />
         <Intro />
         <Vids />
         <Divider />
         <h1 className='b-text-title'>LEADERSHIP</h1>
         <Team />
         <br />
         <h1 className='b-text-title'>Licenses & Permits</h1>
         <Permits />
         <h1 className='b-text-title'>Our Clients</h1>
         <Client />
         <h1 className='b-text-title'>Our Trusted Partners</h1>
         <Partners />
         <br />
         <Footer />
      </>
   )
}

export default About
