import '../styles/footer.css'
import logo from '../images/logo.png'
import { BsFacebook } from 'react-icons/bs'
import { services } from '../data/services'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { ServiceData } from '../Layout'

const Footer = () => {

    const { setIsRedirected, setServiceHash } = useContext(ServiceData)

    return (
        <>
            <div className='footer'>
                <img className='logo' src={logo} alt='' />
                <div className='socials'>
                    <small>follow us on : </small>
                    <a href='https://www.facebook.com/profile.php?id=100089505407413'><BsFacebook /></a>
                </div>
                <div className='brand'>
                    <div className='brand-footer'>
                        <div className='logotext'>
                            <h1>J.G</h1>
                        </div>
                        <div className='texts'>
                            <h3>CRYSTAL KING</h3>
                            <h5>Engineering Services CO.</h5>
                        </div>
                    </div>
                    <div className='info'>
                        <p>We make sure to provide you the best quality of work at an equitable price.</p>
                        <Link
                            to='/quote'
                        >
                            GET A FREE QUOTE NOW!
                        </Link>
                    </div>
                </div>
                <div className='links'>
                    <div className='links-container'>
                        <div className='link'>
                            <h3>Address</h3>
                            <a href='https://www.google.com/maps/place/The+Valero+Tower/@14.559188,121.020316,17z/data=!4m6!3m5!1s0x3397c908c61d5aa3:0x94a9d883248055bd!8m2!3d14.5591883!4d121.0203162!16s%2Fg%2F11b909r3z0?hl=en&entry=ttu'>Suite 9D 9/F The Valero Tower 122, Valero Street Bel Air Makati City, Makati, Philippines</a>
                            <br />
                            <br />
                            <h3>Gmail</h3>
                            <a href='mailto:.gcrystalking@gmail.com'>j.gcrystalking@gmail.com</a>
                            <br />
                            <br />
                            <h3>Phone</h3>
                            <a href='tel:+63999-333-6470'>0999-333-6470 - Smart</a>
                            <br />
                            <a href='tel:+63977-1826-696'>0977-1826-696 - Globe</a>
                        </div>
                        <div className='link'>
                            <h3>Our Services</h3>
                            {services.map((service, id) => (
                                <li key={id}>
                                    <Link
                                        to={'/services'}
                                        onClick={() => {
                                            setIsRedirected(true)
                                            setServiceHash(service.title)
                                        }}
                                    >
                                        {service.title}
                                    </Link>
                                </li>
                            ))}
                        </div>
                        <div className='link'>
                            <h3>Navigate</h3>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/about'>About us</Link></li>
                            <li><Link to='/projects'>Projects</Link></li>
                            <li><Link to='/contact'>Contacts</Link></li>
                            <br />
                            <h3>Legal</h3>
                            <li><Link to='/privacy'>Privacy Policy</Link></li>
                        </div>
                    </div>
                    <div className='footer-end'>
                        <hr />
                        <p>© Copyright 2023 jgcrystalking.com | All Rights Reserved | Built By: <span><a href='https://mnald12.netlify.app/'>mnald12</a></span>.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer