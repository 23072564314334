import '../styles/welcome.css'
import { useRef } from 'react'
import { useIsInViewport } from '../hooks/useIsInViewport'
import { Link } from 'react-router-dom'
import logo from '../images/jgc2.png'

const Welcome = () => {

    const wel = useRef(null)
    const inWel = useIsInViewport(wel)

    return (
        <>
            <div className={inWel ? 'welcomed' : ''} ref={wel}>
                <div id='lgw' className='welcome'>
                    <div className='welcome-left'>
                        <div>
                            <div className='brand-display'>
                                <div className='logotext'>
                                    <h1>J.G</h1>
                                </div>
                                <div className='texts'>
                                    <h3>CRYSTAL KING</h3>
                                    <h5>Engineering Services CO.</h5>
                                </div>
                            </div>
                            <p className='motto'>We make sure to provide you the best quality of work at an equitable price.</p>
                            <Link className='quote' to='/quote'>GET A FREE QUOTE NOW!</Link>
                        </div>
                    </div>
                    <div className='welcome-right'>
                        <img src={logo} alt='team' loading='lazy' />
                    </div>
                </div>
                <div id='smw' className='welcome'>
                    <div className='welcome-right'>
                        <img src={logo} alt='team' loading='lazy' />
                    </div>
                    <div className='welcome-left'>
                        <div>
                            <div className='brand-display'>
                                <div className='logotext'>
                                    <h1>J.G</h1>
                                </div>
                                <div className='texts'>
                                    <h3>CRYSTAL KING</h3>
                                    <h5>Engineering Services CO.</h5>
                                </div>
                            </div>
                            <p className='motto'>We make sure to provide you the best quality of work at an equitable price.</p>
                            <Link className='quote' to='/quote'>GET A FREE QUOTE NOW!</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Welcome