import '../styles/projects.css'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { projects } from '../data/projects'
import { useEffect, useState } from 'react'
import { getService } from '../hooks/useServiceIcon'
import Divider from '../components/Divider'
import Hero from '../components/Hero'

const Projects = () => {

   useEffect(() => { window.scrollTo(0, 0) }, [])

   const setView = (project) => {
      window.sessionStorage.setItem('viewer-data', JSON.stringify(project))
   }

   const [selectionActive, setActives] = useState('ALL')

   return (
      <>
         <div className='projects'>
            <Hero />
            <div className='btn-group'>
               <button onClick={() => setActives('ALL')} className={selectionActive === 'ALL' ? 'active' : ''}>ALL</button>
               <button onClick={() => setActives('Fire Protection System')} className={selectionActive === 'Fire Protection System' ? 'active' : ''}>Fire Protection System</button>
               <button onClick={() => setActives('Sewage Treatment Plant')} className={selectionActive === 'Sewage Treatment Plant' ? 'active' : ''}>Sewage Treatment Plant</button>
               <button onClick={() => setActives('Water Supply System')} className={selectionActive === 'Water Supply System' ? 'active' : ''}>Water Supply System</button>
               <button onClick={() => setActives('Design Estimate')} className={selectionActive === 'Design Estimate' ? 'active' : ''}>Design Estimate</button>
               <button onClick={() => setActives('Civil Works')} className={selectionActive === 'Civil Works' ? 'active' : ''}>Civil Works</button>
               <button onClick={() => setActives('CCTV')} className={selectionActive === 'CCTV' ? 'active' : ''}>CCTV</button>
               <button onClick={() => setActives('Grounding System')} className={selectionActive === 'Grounding System' ? 'active' : ''}>Grounding System</button>
               <button onClick={() => setActives('Lightning Arrestor')} className={selectionActive === 'Lightning Arrestor' ? 'active' : ''}>Lightning Arrestor</button>
            </div>
            <div className='projects-container'>
               {projects.map((proj, id) => (
                  <div key={id} className={selectionActive === 'ALL' || selectionActive === proj.serviceType ? 'project' : 'project disp-none'}>
                     <h3>{proj.projectName}</h3>
                     <p>{proj.company}</p>
                     <img loading='lazy' src={require(`../images/projects/${proj.images[0]}`)} alt='' />
                     <div className='project-link'>
                        <Link
                           to='/project'
                           onClick={() => setView(proj)}
                        >
                           View Project
                        </Link>
                        <div className='img-count'>
                           {getService(proj.serviceType)}
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <Divider />
         <br />
         <Footer />
      </>
   )
}

export default Projects
