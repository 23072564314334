import '../styles/projects.css'
import Footer from '../components/Footer'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { VscChromeClose } from 'react-icons/vsc'
import { getRelated } from '../methods/getRelated'
import { getService } from '../hooks/useServiceIcon'
import Divider from '../components/Divider'

const Viewer = () => {

    useEffect(() => { window.scrollTo(0, 0) }, [])

    const viewerData = JSON.parse(window.sessionStorage.getItem('viewer-data'))

    useEffect(() => {
        if (viewerData === null) window.location = '/projects'
    }, [viewerData])

    const [view, setView] = useState(viewerData)
    const [current, setCurrent] = useState('p1a.png')
    const [isZoomed, setIsZoomed] = useState(false)

    const zoom = (img) => {
        setCurrent(img)
        setIsZoomed(true)
    }

    return (
        <>
            <div className={isZoomed ? 'zoomed-container zoomed' : 'zoomed-container'}>
                <div className='close'>
                    <button onClick={() => setIsZoomed(false)}><VscChromeClose className='x-icn' /></button>
                </div>
                <img src={require(`../images/projects/${current}`)} alt='' />
            </div>

            <div className='viewer'>
                <div className='details'>
                    <h2>Project Details</h2>
                    <h3>Project Title_</h3>
                    <p>{view.projectName}</p>
                    <br />
                    <h3>Company_</h3>
                    <p>{view.company}</p>
                    <br />
                    <h3>Project Location_</h3>
                    <p>{view.location}</p>
                    <br />
                    <h3>Service Type_</h3>
                    <p>{view.serviceType}</p>
                    <br />
                </div>
                <div className='images' id='images'>
                    {view.images.map((d, i) => (
                        <img alt='' onClick={() => zoom(d)} src={require(`../images/projects/${d}`)} key={i} />
                    ))}
                </div>
            </div>
            <div className='detailed'>
                <h2>Related Projects</h2>
            </div>
            <div className='projects-container'>
                {getRelated(viewerData).map((proj, id) => (
                    <div key={id} className='project'>
                        <h3>{proj.projectName}</h3>
                        <p>{proj.company}</p>
                        <img loading='lazy' src={require(`../images/projects/${proj.images[0]}`)} alt='' />
                        <div className='project-link'>
                            <Link
                                to='/project'
                                onClick={() => {
                                    window.sessionStorage.setItem('viewer-data', JSON.stringify(proj))
                                    setView(proj)
                                    window.scrollTo(0, 0)
                                }}
                            >
                                View Project
                            </Link>
                            <div className='img-count'>
                                {getService(proj.serviceType)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Divider />
            <br />
            <Footer />
        </>
    )
}

export default Viewer