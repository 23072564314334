import Footer from '../components/Footer'
import { useEffect } from 'react'
import { FaLocationDot, FaPhoneFlip } from 'react-icons/fa6'
import { SiGmail } from 'react-icons/si'
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'

const Contacts = () => {

   useEffect(() => { window.scrollTo(0, 0) }, [])

   return (
      <>
         <div className='contact'>
            <Hero />
            <div className='contact-details'>
               <div className='detail-container'>
                  <FaLocationDot className='c-icn' />
                  <div className='details'>
                     <small><b>Visit us</b></small>
                     <p>Suite 9D 9/F The Valero Tower 122, Valero Street Bel Air Makati City, Makati, Philippines</p>
                  </div>
               </div>
               <div className='detail-container'>
                  <SiGmail className='c-icn' />
                  <div className='details'>
                     <small><b>Email us</b></small>
                     <p>j.gcrystalking@gmail.com</p>
                  </div>
               </div>
               <div className='detail-container'>
                  <FaPhoneFlip className='c-icn' />
                  <div className='details'>
                     <small><b>Call us</b></small>
                     <div>
                        <p>0999-333-6470 - Smart</p>
                        <p>0977-1826-696 - Globe</p>
                     </div>
                  </div>
               </div>
            </div>
            <ContactForm />
         </div>
         <Footer />
      </>
   )
}

export default Contacts
