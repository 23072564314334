import '../styles/display.css'
import { useRef } from 'react'
import { useIsInViewport } from '../hooks/useIsInViewport'
import img1 from '../images/home/9.jpg'
import img2 from '../images/home/coll1.jpg'
import img3 from '../images/home/coll4.jpg'
import img4 from '../images/home/10.jpg'

const Display = () => {

    const ref1 = useRef(null)
    const r1 = useIsInViewport(ref1)

    const ref2 = useRef(null)
    const r2 = useIsInViewport(ref2)

    const ref3 = useRef(null)
    const r3 = useIsInViewport(ref3)

    const ref4 = useRef(null)
    const r4 = useIsInViewport(ref4)

    const ref5 = useRef(null)
    const d1 = useIsInViewport(ref5)

    const ref6 = useRef(null)
    const d2 = useIsInViewport(ref6)

    const ref7 = useRef(null)
    const d3 = useIsInViewport(ref7)

    const ref8 = useRef(null)
    const d4 = useIsInViewport(ref8)

    const ref9 = useRef(null)
    const d5 = useIsInViewport(ref9)

    return (
        <>
            <div className='d-row'>
                <div className='display1'>
                    <div className='dl'>
                        <img className={r1 ? 'i1 a1' : 'i1'} ref={ref1} src={img1} alt='' />
                        <img className={r2 ? 'i2 a1' : 'i2'} src={img2} ref={ref2} alt='' />
                    </div>
                    <div className='dr'>
                        <img className={r3 ? 'i3 a1' : 'i3'} src={img3} ref={ref3} alt='' />
                        <img className={r4 ? 'i4 a1' : 'i4'} src={img4} ref={ref4} alt='' />
                    </div>
                </div>
                <div className='display2'>
                    <h2>Why Choose J.G Crystal King?</h2>
                    <div className={d1 ? 'aa1' : ''} ref={ref5} >
                        <h3>Innovation at Core</h3>
                        <p>We thrive on pushing boundaries and exploring innovative solutions that solve complex challenges across industries.</p>
                    </div>
                    <br />
                    <div className={d2 ? 'aa1' : ''} ref={ref6} >
                        <h3>Expert Team</h3>
                        <p>Our team consists of accomplished engineers with diverse skills, ensuring a holistic approach to your engineering needs.</p>
                    </div>
                    <br />
                    <div className={d3 ? 'aa1' : ''} ref={ref7} >
                        <h3>Client-Centric Approach</h3>
                        <p>Your goals and vision are our priority. We collaborate closely to ensure our solutions align with your objectives.</p>
                    </div>
                    <br />
                    <div className={d4 ? 'aa1' : ''} ref={ref8} >
                        <h3>Quality Assurance</h3>
                        <p>With a commitment to excellence, our solutions undergo rigorous testing and validation processes to ensure top-tier quality.</p>
                    </div>
                    <br />
                    <div className={d5 ? 'aa1' : ''} ref={ref9} >
                        <h3>Proven Success</h3>
                        <p>Our portfolio showcases a range of successful projects, highlighting our track record of delivering excellence.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Display