import primary from '../images/titles/primary.png'
import page1a from '../images/titles/about1.png'
import page1b from '../images/titles/about2.png'
import page2a from '../images/titles/serv1.png'
import page2b from '../images/titles/serv2.png'
import page3a from '../images/titles/proj1.png'
import page3b from '../images/titles/proj2.png'
import page4a from '../images/titles/cont1.png'
import page4b from '../images/titles/cont2.png'
import { useLocation } from 'react-router-dom'

const Hero = () => {

    const location = useLocation()

    return (
        <>
            <div className='page-title'>
                <div className='title-container'>
                    <img className='pri' src={primary} alt='pri' />
                    <img className='sec' src={location.pathname === '/about' ? page1a : location.pathname === '/services' ? page2a : location.pathname === '/projects' ? page3a : page4a} alt='sec' />
                    <img className='last' src={location.pathname === '/about' ? page1b : location.pathname === '/services' ? page2b : location.pathname === '/projects' ? page3b : page4b} alt='last' />
                </div>
            </div>
        </>
    )
}

export default Hero